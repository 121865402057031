<template>
  <v-layout wrap justify-center>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 mt-6>
      <v-card elevation="2">
        <v-tabs
          v-model="firstTab"
          color="#766BC0"
          grow
          class="nsbold"
          style="font-size: 16px"
        >
          <v-tab>CURRENT</v-tab>
          <v-tab> PENDING </v-tab>
          <!--------------------current---------------------------->

          <v-tab-item>
            <v-layout wrap>
              <v-flex xs12 pa-sm-6 pa-0>
                <v-card flat class="rounded-xl pa-sm-6 pa-0">
                  <v-layout wrap>
                    <v-flex pa-3 align-self-center text-center xs12>
                      <span class="nsbold"
                        >Current Status: {{ formatDate(tday) }}</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-tabs color="#766BC0" v-model="tab" align-with-title>
                    <v-tabs-slider color="#766BC0"></v-tabs-slider>

                    <v-tab
                      class="nsbold"
                      style="font-size: 16px"
                      v-for="item in statusItems"
                      :key="item"
                      @click="getCurrent(item)"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <!-- <v-tab-item>
         
      </v-tab-item> -->

                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="item in statusItems" :key="item">
                      <v-card flat>
                        <v-layout wrap justify-center>
                          <v-flex xs12 v-if="mylist" px-sm-6 pt-2>
                            <v-layout
                              wrap
                              pa-2
                              class="nsbold"
                              style="font-size: 16px"
                            >
                              <v-flex xs12 pa-2 v-if="mylist.length > 0">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">No.</th>
                                        <th class="text-left">QR Id</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Ad.No.</th>
                                        <th class="text-left">Type</th>
                                        <th class="text-left">Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item, i) in mylist" :key="i">
                                        <td>
                                          <span v-if="page == 1">
                                            {{ i + 1 }}
                                          </span>
                                          <span v-else>
                                            {{ i + 1 + 20 * (page - 1) }}
                                          </span>
                                        </td>
                                        <td>
                                          {{ item.clothid.clothbarid }}
                                        </td>
                                        <td>
                                          {{ item.studentid.name }}
                                        </td>
                                        <td>
                                          <span
                                            v-if="item.studentid.admissionNo"
                                          >
                                            {{ item.studentid.admissionNo }}
                                          </span>
                                          <span v-else>NA</span>
                                        </td>

                                        <td>
                                          <span
                                            v-if="item.clothid.clothType.name"
                                          >
                                            {{ item.clothid.clothType.name }}
                                          </span>
                                          <span v-else>NA</span>
                                        </td>
                                        <td>
                                          {{ formatDate(item.update_date) }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-flex>
                              <v-flex
                                xs12
                                align-self-center
                                text-center
                                pa-2
                                v-else
                              >
                                <span class="nsregular"
                                  >Currently no item found!</span
                                >
                              </v-flex>
                              <!-- <v-flex xs2>QR Id</v-flex>
                                <v-flex xs3>Name</v-flex>
                                <v-flex xs2>Ad.No</v-flex>
                                <v-flex xs2>Type</v-flex>
                                <v-flex xs2>Date</v-flex> -->
                            </v-layout>
                            <!-- </v-card> -->
                            <!-- <v-card
                              v-for="(item, i) in mylist"
                              :key="i"
                              elevation="0"
                            >
                              <v-layout
                                wrap
                                pa-2
                                class="nsregular"
                                style="font-size: 16px"
                              >
                                <v-flex xs2>{{
                                  item.clothid.clothbarid
                                }}</v-flex>
                                <v-flex xs3>
                                  <span v-if="item.studentid">
                                    {{ item.studentid.name }}
                                  </span>
                                </v-flex>
                                <v-flex xs2>{{
                                  item.studentid.admissionNo
                                }}</v-flex>
                                <v-flex xs2 v-if="item.clothid.clothType">{{
                                  item.clothid.clothType.name
                                }}</v-flex>
                                <v-flex xs3>{{
                                  formatDate(item.update_date)
                                }}</v-flex>

                              </v-layout>
                              <v-divider></v-divider>
                            </v-card> -->
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <!--------------------pending---------------------------->
          <v-tab-item>
            <v-card elevation="0" class="rounded-xl ma-6">
              <v-layout wrap v-if="sample">
                <v-flex xs12 v-if="sample.length > 0">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in sample" :key="i">
                      <v-expansion-panel-header
                        v-if="item._id"
                        :style="i % 2 == 0 ? 'background-color:#eeeeee' : ''"
                      >
                        <v-layout wrap>
                          <v-flex
                            text-left
                            xs6
                            px-1
                            v-if="item.data[0].clothid.clothType"
                          >
                            <span
                              class="nsregular"
                              style="font-size: 18px"
                              v-if="item.data[0].clothid.clothType.name"
                              >{{ item.data[0].clothid.clothType.name }}
                            </span>
                          </v-flex>

                          <v-flex text-left xs6 px-1 v-else>
                            <v-spacer></v-spacer>
                          </v-flex>
                          <v-flex text-right xs6 px-1>
                            <span class="nsregular" style="font-size: 18px">
                              Count: {{ item.count }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">No.</th>
                                <th class="text-left">Barcode Id</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Ad.No</th>
                                <th class="text-left">Type</th>
                                <th class="text-left">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value, i) in item.data" :key="i">
                                <td>
                                  <span v-if="page == 1">
                                    {{ i + 1 }}
                                  </span>
                                  <span v-else>
                                    {{ i + 1 + 20 * (page - 1) }}
                                  </span>
                                </td>
                                <td>{{ value.clothid.clothbarid }}</td>
                                <!-- <td>
                                  <span v-if="value.studentid[0].name">
                                    {{ value.studentid[0].name }}
                                  </span>
                                </td>
                                <td>{{ value.studentid[0].admissionNo }}</td> -->
                                <td>
                                  <span v-if="value.studentid.name">
                                    {{ value.studentid.name }}
                                  </span>
                                </td>
                                <td>{{ value.studentid.admissionNo }}</td>

                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{ formatDate(value.INdate) }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-12>
                  No pending clothes found!
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-center>
              <v-flex xs12 v-if="mylist" px-6 pt-2>
                <v-card round style="background-color:#EEEEEE">
                </v-card>

           
                <v-card v-for="(item, i) in pending" :key="i" elevation="0">
    
                  <v-layout wrap pa-2 class="nsregular" style="font-size:16px;">
                    <v-flex xs12 pa-6 v-for="(sub,s) in item.data" :key="s">
                      {{ sub.INdate }}<br/>{{sub.clothid.QRcode}}<br/>{{sub.clothid.clothType.name}}
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                </v-card>
              </v-flex>
            </v-layout> -->
            </v-card>
          </v-tab-item>
        </v-tabs>

        <v-layout
          wrap
          pt-3
          v-if="statusNow == 'IN' && firstTab == 0 && firstTab != 1"
        >
          <v-flex xs12 text-center>
            <v-pagination
              small
              total-visible="7"
              v-model="inpage"
              color="#766BC0"
              :length="test"
            >
            </v-pagination>
          </v-flex>
        </v-layout>

        <v-layout
          wrap
          pt-3
          v-if="statusNow == 'OUT' && firstTab == 0 && firstTab != 1"
        >
          <v-flex xs12 text-center>
            <v-pagination
              small
              total-visible="7"
              v-model="outpage"
              color="#766BC0"
              :length="test"
            >
            </v-pagination>
          </v-flex>
        </v-layout>

        <v-layout
          wrap
          pt-3
          v-if="statusNow != 'OUT' && firstTab != 0 && firstTab == 1"
        >
          <v-flex xs12 text-center>
            <v-pagination
              small
              total-visible="7"
              v-model="pendingpage"
              color="#766BC0"
              :length="plimit"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      firstTab: "CURRENT",
      test: 0,
      tday: new Date(),
      page: 1,
      inpage: 1,
      outpage: 1,
      Pagelength: 0,
      ServerError: false,
      appLoading: false,
      a: "getlist/presentstatus/in",
      b: "getlist/presentstatus/out",
      //pagination

      limit: 20,

      // pages: 0,
      // pageCount: 0,
      // currentPage: 1,
      sample: [],
      Tcount: null,
      user: [],
      tab: null,
      statusItems: null,
      mylist: [],
      statusNow: null,
      pendingpage: 1,
      plimit: null,
      items: ["web", "shopping", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  mounted() {
    if (this.$route.query.val == "Pending") {
      this.firstTab = 1;
    }
    this.getData();
    this.pendingData();
  },
  watch: {
    firstTab() {},
    // page() {
    //   this.getCurrent();
    // },
    inpage() {
      this.getCurrent("IN");
    },
    outpage() {
      this.getCurrent("OUT");
    },
    pendingpage() {
      this.pendingData();
    },
  },
  methods: {
    pendingData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/laundry/pending/clothhistory",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.pendingpage,
          limit: 40,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.pending = response.data.data;
            this.sample = response.data.data;
            this.plimit = Math.ceil(response.data.totalLength / 40);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/view/clothstatus",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.user = response.data.data;
            this.statusItems = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
            if (this.statusItems.length > 0) {
              this.getCurrent(this.statusItems[0]);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },

    getCurrent(status) {
      this.statusNow = status;
      if (status == "IN") {
        // this.outpage = 1;
        this.page = this.inpage;
      } else {
        //  this.inpage = 1;
        this.page = this.outpage;
      }
      this.mylist = null;
      let Url = "";
      if (status == "IN") {
        Url = this.a;
      } else {
        Url = this.b;
      }
      if (Url != "") {
        axios({
          method: "POST",
          url: Url,
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            presentStatus: status,
            page: this.page,
            limit: 20,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.mylist = response.data.data;
              this.pagelength = Math.ceil(response.data.totalLength / 20);
              this.test = Math.ceil(response.data.totalLength / 20);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>